import React from "react";
import ProjectPreview from "./project-preview";
import Contacts from "./contacts";
import HeadingSection from "./preview-grid-heading";

import styles from "./project-preview-grid.module.css";
import gridStyles from "./grid.module.css";

function ProjectPreviewGrid({ page }) {
  return (
    <>
      <HeadingSection content={page._rawDescription} sideContent={<Contacts />} hideMore />

      <div className={gridStyles.container}>
        {page.projects &&
          page.projects.map(project => (
            <div className={gridStyles.block} key={project.id}>
              <ProjectPreview {...project} />
            </div>
          ))}
      </div>
    </>
  );
}

ProjectPreviewGrid.defaultProps = {
  page: {}
};

export default ProjectPreviewGrid;
