import React, { useEffect, useRef, useState } from "react";
import Player from "@vimeo/player";

import styles from "./media.module.css";

const VideoPlayer = ({ url, backgroundColor }) => {
  const playerRef = useRef(null);
  const playerInstance = useRef(null);

  useEffect(() => {
    if (playerRef.current) {
      playerInstance.current = new Player(playerRef.current, {
        url,
        title: false,
        byline: false,
        responsive: true,
        background: true,
        loop: true
      });
    }

    return () => {
      playerInstance.current.destroy();
    };
  }, [url, playerRef]);

  const containerStyle = {
    backgroundColor
  };

  return (
    <figure className={styles.videoPreview} style={containerStyle}>
      <div ref={playerRef}></div>
    </figure>
  );
};

VideoPlayer.defaultProps = {
  backgroundColor: "#fff"
};

export default VideoPlayer;
