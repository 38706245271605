import React from "react";
import Link from "./Link";
import { cn, buildImageObj, buildSrcSet } from "../lib/helpers";
import { prepCategories } from "../lib/meta";
import { imageUrlFor } from "../lib/image-url";
import { useLazyImage } from "../lib/lazyload";

import styles from "./project-preview.module.css";

import VideoPlayer from "./PreviewVideoPlayer";

const sizes = [{ width: 600 }, { width: 1000 }, { width: 1400 }, { width: 2000 }];

function ProjectPreview(props) {
  const { title, slug, categories } = props;
  const { mainImage, mainVideo = {} } = props;

  const hasImage = mainImage && mainImage.asset;
  const hasVideo = Boolean((mainVideo || {}).url);

  const imageUrl = hasImage ? imageUrlFor(buildImageObj(mainImage)) : "";

  useLazyImage();

  return (
    <article className={styles.root}>
      <Link className={styles.link} to={`/projects/${slug.current}`}>
        {hasVideo && <VideoPlayer {...mainVideo} />}

        {!hasVideo && hasImage && (
          <figure className={styles.previewImage}>
            <img
              data-srcset={buildSrcSet(imageUrl, sizes)}
              data-sizes="(max-width: 48em) 100vw, 50vw"
              alt={mainImage.alt}
              loading="lazy"
              className="lazy"
            />
          </figure>
        )}
        <div className={styles.titleContainer}>
          <h2 className={styles.title}>{title}</h2>
          <span className={styles.categories}>{prepCategories(categories)}</span>
        </div>
      </Link>
    </article>
  );
}

export default ProjectPreview;
