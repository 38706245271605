import React from "react";
import { graphql } from "gatsby";
import Container from "../components/container";
import GraphQLErrorList from "../components/graphql-error-list";
import ProjectPreviewGrid from "../components/project-preview-grid";
import SEO from "../components/seo";
import Layout from "../containers/layout";

import { toPlainText } from "../lib/helpers";

export const query = graphql`
  query IndexPageQuery {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      title
      description
      keywords
    }
    page: sanityProjectsPage(_id: { eq: "projectsPage" }) {
      _rawDescription
      projects {
        id
        categories {
          _id
          title
        }
        mainVideo {
          url
          backgroundColor
        }
        mainImage {
          crop {
            _key
            _type
            top
            bottom
            left
            right
          }
          hotspot {
            _key
            _type
            x
            y
            height
            width
          }
          asset {
            _id
          }
          alt
        }
        title
        _rawExcerpt
        slug {
          current
        }
      }
    }
  }
`;

const IndexPage = props => {
  const { data, errors } = props;

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    );
  }

  const site = (data || {}).site;
  const page = (data || {}).page;

  if (!site) {
    throw new Error(
      'Missing "Site settings". Open the studio at http://localhost:3333 and add some content to "Site settings" and restart the development server.'
    );
  }

  const image = page.projects ? page.projects[0].mainImage : undefined;
  const description = toPlainText(page._rawDescription.slice(0, 1)) || site.description;

  return (
    <Layout>
      <SEO title={site.title} description={description} keywords={site.keywords} image={image} />
      <Container>{page.projects && <ProjectPreviewGrid page={page} />}</Container>
    </Layout>
  );
};

export default IndexPage;
